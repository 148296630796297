import { useEffect, useState } from 'react';
import { Button, Form, Modal, Tab, Table, Tabs, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';
import { BsArrowClockwise } from "react-icons/bs";
import { BsFillRouterFill } from "react-icons/bs";
import { BsWifi } from "react-icons/bs";
import { BsFillXCircleFill } from "react-icons/bs";


function TableComponent(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [show, setShow] = useState(false);
  const [showExcluir, setShowExcluir] = useState(false);
  const [ont, setOnt] = useState('')
  const [olt, setOlt] = useState('')
  const [ssid, setSsid] = useState('')
  const [password, setPassword] = useState('')
  const [activeTab, setActiveTab] = useState('ssidpass');
  const [responseStatus, setResponseStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();

  const handleCheckboxChange = (event, onu) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedRows([...selectedRows, onu]);
    } else {
      setSelectedRows(selectedRows.filter(row => row.id !== onu.id));
    }
  };

  const handleRunFunction = () => {

    const responseToken = localStorage.getItem('token');

    const dados = {
      'olt':props.oltSelect,
      'onus': selectedRows.map((data) => data.onts)
  }

    axios.post('http://138.97.13.78:8000/api-v1/ms/massivoApagarOnt/', dados, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + responseToken
      },
      credentials: 'include'
    })
      .then(response => {
        handleClear();
        setLoading(false);
        setResponseStatus(response.data.status);
        window.location.reload(false);

      })
      .catch(error => {
        console.error(error);
        // Lida com erros da requisição
      });
    

  };

  const handleClear = () => {
    // Limpa os estados
    setSsid('');
    setPassword('');
  };

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    handleClear();
    setResponseStatus(''); // Call handleClear on tab change
  }, [activeTab]);

  const handleClose = () => {
    setOnt('')
    setOlt('')
    setSsid('')
    setPassword('')
    setResponseStatus('');
    setShow(false)
    setShowExcluir(false)
  };
  var handleShow = (ontSelecionada, oltSelecionada) => {
    setOnt(ontSelecionada)
    setOlt(oltSelecionada)
    setShow(true);
  }

  const handleOnuShow = (onu) => {
    const API_URL = 'http://138.97.13.78:8000/api-v1/ms/coletorIp/';
    const responseToken = 'ab250b923927823bba30417bb011180d9ee20ae2';
    const payload = { "onu": onu };

    axios
      .post(API_URL, payload, {
        headers: {
          'Authorization': 'Token ' + responseToken,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        window.open('http://' + String(response.data['ip']) + ':8080/', 'Blank');
      })
      .catch(function (error) {
        console.error(error);
      });
  };


  var handleExcluir = (ontSelecionada, oltSelecionada) => {
    setOnt(ontSelecionada)
    setOlt(oltSelecionada)
    setShowExcluir(true);

  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (column) => {
    const direction =
      column === sortColumn && sortDirection === 'asc' ? 'desc' : 'asc';

    setSortColumn(column);
    setSortDirection(direction);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = {
      ssid: ssid,
      password: password,
      ont: ont,
      olt: olt,
    };



    const responseToken = localStorage.getItem('token');

    axios.post('http://138.97.13.78:8000/api-v1/ms/changeWifi/', formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + responseToken
      },
      credentials: 'include'
    })
      .then(response => {
        handleClear();
        setLoading(false);
        setResponseStatus(response.data.status);
        // Faça o processamento necessário com a resposta
      })
      .catch(error => {
        console.error(error);
        // Lida com erros da requisição
      });
  };

  const handleOnuStatus = (onu) => {
    document.getElementById(onu).innerHTML = `<div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>`

    const formData = {
      pppoe: onu,
      departamento: "SUPORTE TECNICO"
    };

    const responseToken = localStorage.getItem('token');

    axios.post('http://138.97.13.78:8000/api-v1/ms/bot-onu-suporte/', formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + responseToken
      },
      credentials: 'include'
    })
      .then(response => {
        let energia = response.data['energia']
        let inativo = response.data['inativo']
        let disabilitada = response.data['sinal']
        document.getElementById(onu).innerHTML = "<div>Energia:" + energia + '<br>Inativo:' + inativo + '<br>Rompimento: ' + disabilitada + '</div>'


      })
      .catch(error => {
        console.error(error);
        // Lida com erros da requisição
      });
  };

  const handleSubmitExcluir = (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = {
      ont: ont,
      olt: olt,
    };



    const responseToken = localStorage.getItem('token');

    axios.post('http://138.97.13.78:8000/api-v1/ms/apagarOnt/', formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + responseToken
      },
      credentials: 'include'
    })
      .then(response => {
        handleClear();
        setLoading(false);
        setResponseStatus(response.data.status);
        window.location.reload(false);

      })
      .catch(error => {
        console.error(error);
        // Lida com erros da requisição
      });
  };

  const filteredData = props.data.filter((item) => {
    const values = Object.values(item).map((value) =>
      typeof value === 'string' ? value.toLowerCase() : value
    );



    return values.some((value) =>
      String(value).includes(searchTerm.toLowerCase())
    );
  });

  const sortedData = [...filteredData].sort((itemA, itemB) => {
    const valueA = itemA[sortColumn];
    const valueB = itemB[sortColumn];

    if (typeof valueA === 'string') {
      return valueA.localeCompare(valueB) * (sortDirection === 'asc' ? 1 : -1);
    } else if (typeof valueA === 'number') {
      return (valueA - valueB) * (sortDirection === 'asc' ? 1 : -1);
    } else {
      return (
        String(valueA).localeCompare(String(valueB)) *
        (sortDirection === 'asc' ? 1 : -1)
      );
    }
  });

  const countOnlineONUs = props.data.filter(
    (onu) => onu.rx_ont !== 'unknown' && onu.rx_on !== ''
  ).length;

  const countOfflinesONUs = sortedData.filter(
    (onu) => onu.rx_ont === 'unknown' || onu.rx_on === ''
  ).length;

  const countDbOkONUs = sortedData.filter(
    (onu) => onu.rx_ont > -27
  ).length;

  const countDbNokONUs = sortedData.filter(
    (onu) => onu.rx_ont < -27
  ).length;
  const countDbNokOlts = sortedData.filter(
    (onu) => onu.Rx_Olt < -30
  ).length;
  const reflexao = sortedData.filter(
    (onu) => onu.Rx_Olt - onu.rx_ont < -7
  ).length;

  return (
    <>
    <p>Ajustes Recomendados para uma Rede Eficiente.</p>
    <div style={{ 'marginTop': '1%', 'display': 'flex', 'justifyContent': 'space-evenly', 'marginBottom': '-4%' }}>
        <Button variant="secondary" style={{ 'width': '100%' }}>Online OK</Button>
        <Button variant="secondary" style={{ 'width': '100%' }}>Offline NOK</Button>
        <Button variant="secondary" style={{ 'width': '100%' }}>Db Bom &gt; -27 </Button>
        <Button variant="secondary" style={{ 'width': '100%' }}>At. Onu &gt; -27</Button>
        <Button variant="secondary" style={{ 'width': '100%' }}>At. Olt &gt; -30</Button>
        <Button variant="secondary" style={{ 'width': '100%' }}>Reflexão &lt; 8 </Button>
      </div>
      <div style={{ 'marginTop': '4.05%', 'display': 'flex', 'justifyContent': 'space-evenly', 'marginBottom': '-4%' }}>
        <Button variant="success" style={{ 'width': '100%' }}>Online ({countOnlineONUs})</Button>
        <Button variant="danger" style={{ 'width': '100%' }}>Offline ({countOfflinesONUs})</Button>
        <Button variant="success" style={{ 'width': '100%' }}>Db Bom ({countDbOkONUs})</Button>
        <Button variant="danger" style={{ 'width': '100%' }}>At. Onu ({countDbNokONUs})</Button>
        <Button variant="warning" style={{ 'width': '100%' }}>At. Olt ({countDbNokOlts})</Button>
        <Button variant="info" style={{ 'width': '100%' }}>Reflexão ({reflexao})</Button>
        
      </div>
      
      <Row style={{ 'margin-top': '5%',
    'margin-bottom': '-4%'}}>
      <Col xs={2}>
     <Button className='mr-3' onClick={() => handleRunFunction()} variant="danger">Excluir</Button>
     </Col>
     <Col xs={2}>
          <input
          type="text"
          placeholder="Filtrar"
          value={searchTerm}
          onChange={handleSearch}
        />
        </Col>
     </Row>
      <Table style={{ marginTop: '5%' }}>
         
        <thead>
          
          <tr>
            
            <th >#</th> 
            <th onClick={() => handleSort('onts')}>
              LOC
              {sortColumn === 'onts' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('rx_ont')}>
              ONU
              {sortColumn === 'rx_ont' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('Rx_Olt')}>
              OLT
              {sortColumn === 'Rx_Olt' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('temp_ont')}>
              TEMP
              {sortColumn === 'temp_ont' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('serial')}>
              SERIAL
              {sortColumn === 'serial' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('modelo')}>
              PPPOE
              {sortColumn === 'modelo' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('descricao')}>
              Descrição
              {sortColumn === 'descricao' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('bairro')}>
              Bairro
              {sortColumn === 'bairro' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('cto')}>
              CTO
              {sortColumn === 'cto' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('mac_address')}>
              Mac Address
              {sortColumn === 'mac_address' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('bloqueado')}>
              Bloqueado
              {sortColumn === 'bloqueado' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('horarioQueda')}>
              Ult. Queda
              {sortColumn === 'horarioQueda' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((onu) => (
            onu.Rx_Olt - onu.rx_ont < -8 ? (
              <tr
                key={onu.id}
                id={onu.onts}
                style={{ backgroundColor: 'blue', color: 'white' }}
              >
                <td colSpan={1} >
                <input
                  type="checkbox"
                  checked={selectedRows.includes(onu)}
                  onChange={(event) => handleCheckboxChange(event, onu)}
                />
              </td>
                <td>{onu.onts}</td>
                <td>{onu.rx_ont}</td>
                {onu.Rx_Olt < -30 ? (<td style={{ backgroundColor: 'red', color: 'white' }}>{onu.Rx_Olt}</td>) : (<td>{onu.Rx_Olt}</td>)}
                <td>{onu.temp_ont}</td>
                <td>{onu.serial}</td>
                <td>{onu.modelo}</td>
                <td>{onu.descricao}</td>
                <td>{onu.bairro}</td>
                <td>{onu.cto}</td>
                <td>{onu.mac_address}</td>
                <td>{onu.bloqueado}</td>
                <td id={onu.modelo}></td>
                <td>
                  <Row>
                    <Col xs={2}>
                      <OverlayTrigger overlay={<Tooltip id="tooltip1">Ult. Alarm</Tooltip>}>
                        <a onClick={() => handleOnuStatus(onu.modelo)} style={{ cursor: 'pointer' }}><BsArrowClockwise /></a>
                      </OverlayTrigger>
                    </Col>
                    <Col xs={2}>
                      <OverlayTrigger overlay={<Tooltip id="tooltip2">Acessar</Tooltip>}>
                        <a onClick={() => handleOnuShow(onu.modelo)} style={{ cursor: 'pointer' }}><BsFillRouterFill /></a>
                      </OverlayTrigger>
                    </Col>
                    <Col xs={2}>
                      <OverlayTrigger overlay={<Tooltip id="tooltip3">Troca Wifi</Tooltip>}>
                        <a onClick={() => handleShow(onu.onts, onu.Olt)} style={{ cursor: 'pointer' }}><BsWifi /></a>
                      </OverlayTrigger>
                    </Col>
                    <Col xs={2}>
                      <OverlayTrigger overlay={<Tooltip id="tooltip4">Excluir</Tooltip>}>
                        <a onClick={() => handleExcluir(onu.onts, onu.Olt)} style={{ cursor: 'pointer' }}><BsFillXCircleFill /></a>
                      </OverlayTrigger>
                    </Col>
                  </Row>


                </td>
              </tr>
            ) : (
              <tr
                key={onu.id}
                id={onu.onts}
                style={{ backgroundColor: onu.rx_ont < -27 ? 'orange' : onu.rx_ont === 'unknown' ? 'red' : 'green', color: onu.rx_ont < -27 ? 'white' : onu.rx_ont === 'unknown' ? 'white' : 'white' }}>
                 <td colSpan={1}>
                 <input
                  type="checkbox"
                  checked={selectedRows.includes(onu)}
                  onChange={(event) => handleCheckboxChange(event, onu)}
                />
              </td> 
                
                <td>{onu.onts}</td>
                <td>{onu.rx_ont}</td>
                {onu.Rx_Olt < -30 ? (<td style={{ backgroundColor: 'red', color: 'white' }}>{onu.Rx_Olt}</td>) : (<td>{onu.Rx_Olt}</td>)}
                <td>{onu.temp_ont}</td>
                <td>{onu.serial}</td>
                <td>{onu.modelo}</td>
                <td>{onu.descricao}</td>
                <td>{onu.bairro}</td>
                <td>{onu.cto}</td>
                <td>{onu.mac_address}</td>
                <td>{onu.bloqueado}</td>
                <td id={onu.modelo}></td>
                <td>
                <Row>
                    <Col xs={2}>
                      <OverlayTrigger overlay={<Tooltip id="tooltip5">Ult. Alarm</Tooltip>}>
                        <a onClick={() => handleOnuStatus(onu.modelo)} style={{ cursor: 'pointer' }}><BsArrowClockwise /></a>
                      </OverlayTrigger>
                    </Col>
                    <Col xs={2}>
                      <OverlayTrigger overlay={<Tooltip id="tooltip6">Acessar</Tooltip>}>
                        <a onClick={() => handleOnuShow(onu.modelo)} style={{ cursor: 'pointer' }}><BsFillRouterFill /></a>
                      </OverlayTrigger>
                    </Col>
                    <Col xs={2}>
                      <OverlayTrigger overlay={<Tooltip id="tooltip7">Troca Wifi</Tooltip>}>
                        <a onClick={() => handleShow(onu.onts, onu.Olt)} style={{ cursor: 'pointer' }}><BsWifi /></a>
                      </OverlayTrigger>
                    </Col>
                    <Col xs={2}>
                      <OverlayTrigger overlay={<Tooltip id="tooltip8">Excluir</Tooltip>}>
                        <a onClick={() => handleExcluir(onu.onts, onu.Olt)} style={{ cursor: 'pointer' }}><BsFillXCircleFill /></a>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </td>
              </tr>
            )

          ))}
        </tbody>
      </Table>
      <Modal show={showExcluir} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Excluir Onu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {responseStatus === 'OK' && (
            <Alert variant="success">
              Modificado com sucesso!
            </Alert>
          )}
          {responseStatus === 'error' && (
            <Alert variant="danger">
              Erro Não foi Modificado!
            </Alert>
          )}
          <h6>Deseja mesmo excluir essa onu {ont} da {olt}</h6>
          <Button variant="secondary" className="mr-3" onClick={handleClose}>
            Fechar
          </Button>

          <Button variant="danger" onClick={handleSubmitExcluir}>
            Excluir
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Troca de Wifi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="ssidpass"
            activeKey={activeTab}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={handleTabSelect}
          >
            <Tab eventKey="ssidpass" title="SSID + PASS" >
              <div>
                {responseStatus === 'OK' && (
                  <Alert variant="success">
                    Modificado com sucesso!
                  </Alert>
                )}
                {responseStatus === 'error' && (
                  <Alert variant="danger">
                    Erro Não foi Modificado!
                  </Alert>
                )}

                <Form>
                  <div align="center">{ont} - {olt}</div>
                  <Form.Group className="mb-3" controlId="somente o nome">
                    <Form.Label>SSID</Form.Label>
                    <Form.Control type="text" placeholder="somente o nome" value={ssid} onChange={(e) => setSsid(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>PASSWORD</Form.Label>
                    <Form.Control type="text" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Button variant="secondary" className="mr-3" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                      Salvar
                    </Button>
                    {loading === true && (
                      <div class="spinner-border text-primary ml-3" role="status" style={{ 'vertical-align': 'middle' }}>
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </Form.Group>
                </Form>
              </div>


            </Tab>
            <Tab eventKey="ssid" title="SSID">
              {responseStatus === 'OK' && (
                <Alert variant="success">
                  Modificado com sucesso!
                </Alert>
              )}
              {responseStatus === 'error' && (
                <Alert variant="danger">
                  Erro Não foi Modificado!
                </Alert>
              )}

              <div>
                <Form>
                  <Form.Group className="mb-3" controlId="somente o nome">
                    <Form.Label>SSID</Form.Label>
                    <Form.Control type="text" placeholder="somente o nome" value={ssid} onChange={(e) => setSsid(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Button variant="secondary" className="mr-3" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                      Salvar
                    </Button>
                    {loading === true && (
                      <div class="spinner-border text-primary ml-3" role="status" style={{ 'vertical-align': 'middle' }}>
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </Form.Group>
                </Form>
              </div>
            </Tab>
            <Tab eventKey="pass" title="PASS" >
              {responseStatus === 'OK' && (
                <Alert variant="success">
                  Modificado com sucesso!
                </Alert>
              )}
              {responseStatus === 'error' && (
                <Alert variant="danger">
                  Erro Não foi Modificado!
                </Alert>
              )}

              <div>
                <Form>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>PASSWORD</Form.Label>
                    <Form.Control type="text" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Button variant="secondary" className="mr-3" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                      Salvar
                    </Button>
                    {loading === true && (
                      <div class="spinner-border text-primary ml-3" role="status" style={{ 'vertical-align': 'middle' }}>
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </Form.Group>
                </Form>
              </div>
            </Tab>
          </Tabs>

        </Modal.Body>



      </Modal>
    </>
  );
}

export default TableComponent;