import React, { Component } from 'react'
import { Container, Card, Form, FloatingLabel, Button } from 'react-bootstrap'
export class Cadastro extends Component {
    render() {
        return (
            <>
                <Container align="center">
                    <Card>
                        <br></br>
                        <Card.Title>Cadastro Fonercedores</Card.Title>
                        <Card.Body>
                            <Form>
                                <FloatingLabel controlId="Empresa" label="Empresa">
                                    <Form.Control type="text" placeholder="Empresa" required/>
                                </FloatingLabel>
                                <br></br>
                                <FloatingLabel controlId="Telefone" label="Telefone">
                                    <Form.Control type="tel" placeholder="Telefone" pattern="[0-9]{2}[0-9]{5}-[0-9]{4}" required/>
                                </FloatingLabel>
                                <br></br>
                                <FloatingLabel controlId="Cartegoria" label="Cartegoria">
                                    <Form.Control type="text" placeholder="Cartegoria" required/>
                                </FloatingLabel>
                                <br></br>
                                <FloatingLabel controlId="E-mail" label="E-mail">
                                    <Form.Control type="email" placeholder="E-mail" required/>
                                </FloatingLabel>
                                <br></br>
                                <Button type="submit">Cadastrar</Button>
                                <br></br>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            </>
        )
    }
}

export default Cadastro