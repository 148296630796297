import React, { Component } from 'react'
import {Container} from 'react-bootstrap'
export class Relatorios extends Component {
  render() {
    return (
      <Container >
          containers
      </Container>
    )
  }
}

export default Relatorios