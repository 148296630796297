import React from 'react'
import { DropdownButton, Dropdown, Col } from 'react-bootstrap'

export default function atendimentos() {
    return (
        <Col>
            <DropdownButton className='custom-dropdown'
                key='down'
                id={`dropdown-button-drop-down`}
                drop='down'
                variant="secondary"
                title='Atendimentos'
            >

                <Dropdown.Item href="#">Fila de Atendimento</Dropdown.Item>
                <Dropdown.Item href="#">Meus Atendimentos</Dropdown.Item>
                <Dropdown.Item href="#">Histórico de Atendimento</Dropdown.Item>
                <Dropdown.Item href="#">Minhas Estatisticas</Dropdown.Item>
            </DropdownButton>
        </Col>
    )
}
