import React, { Component } from 'react'
import './Whatsapp.css'
import { Alert, Container, Button, Form } from 'react-bootstrap'
import Axios from 'axios'

export class Whatsapp extends Component {
    constructor() {
        super();
        this.state = {
            file: '',
            alert: false,
            
        }
    }
    enviarCobranca = () => {
        const data = new FormData()
        data.append('arquivo', this.state.file)
        const url = 'http://138.97.13.78:8000/financeiro/whatsapp/enviarArquivos/'


        let axiosConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': 'Token ' + localStorage.getItem('token')
            },
            credentials: "include",
        }
        Axios.post(url, data, axiosConfig).then(res => { // then print response status
            this.setState({ alert: true })
        })

    }

    render() {
        return (

            <Container>
                <Alert show={this.state.alert} variant="success" onClose={() => this.setState({alert:false})} dismissible>
                    <Alert.Heading>Lista de mensagens enviadas com sucesso!</Alert.Heading>
                </Alert>
                <Form className="FormWhatsapp" >
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label >Lista de Numero e Mensagem</Form.Label>
                        <Form.Control type="file" onChange={(e) => this.setState({ file: e.target.files[0] })} />
                        <Button className="btnCobranca" onClick={() => this.enviarCobranca()}>Enviar Cobrança</Button>
                    </Form.Group>
                </Form>
            </Container>
        )
    }
}

export default Whatsapp