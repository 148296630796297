import React, { Component } from 'react'
import axios from "axios";
import Table from "react-bootstrap/Table";
import NokiaOlt from './oltNokia.png'
import { Card,  Button, Modal   } from 'react-bootstrap';
import TableOnu from '../../components/table_onus/Table'
import './HomeSn2.css'
import Alert from 'react-bootstrap/Alert';

export class Home extends Component {
  constructor() {
    super();

    this.state = {
      usuario: "",
      data: [],
      olt: [],
      oltSelect: '',
      oltAtualizar:false,
      focalOlt:'',
      reponseStatusOlt:'',
    };
  }

  componentDidMount() {
    const API_URL = "http://138.97.13.78:8000/monitoramento/Olts/";
    const responseToken = localStorage.getItem('token')
    axios
      .get(API_URL, {
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': 'Token ' + localStorage.getItem('token')
          'Authorization': 'Token ' + responseToken
        },
        credentials: "include",
      })
      .then((response) => {

        console.log(response.data.results)
        this.setState({ data: response.data.results });
      })
      .catch((error) => {
        console.log('aqui')
        console.log(error);
      });
  }
  handleAtualizarOlt = (olt) => {
    this.setState({oltAtualizar:true, focalOlt:olt})

  }

  AtualizarOlts = async (olt) => {
    olt = olt.replace('OLT ','').replace(' ','-')
    this.setState({reponseStatusOlt:'aguarde'});
    
    const url = "https://serv.redebrtelecom.com.br/monitoramento/ColetaDados/" + olt;
    console.log(url)
    try {
      const response = await axios.get(url, {
          headers: {
              'Authorization': 'Token ab250b923927823bba30417bb011180d9ee20ae2',
              'Content-Type': 'application/json',
              'Cookie': 'csrftoken=PxPbI6iGkJAOAIdVqA09hjMgUkUKglUPIIfqRrzBRJegzLi2tCH13WcOdbAfv8fc'
          }
      });

      // Check if the response is successful
      if (response.status !== 200) {
          throw new Error('Network response was not ok');
      }

      this.setState({reponseStatusOlt:'OK'});
  } catch (error) {
      this.setState({reponseStatusOlt:'error'});
  }
}
  handleCloseOlt = () => {
    this.setState({oltAtualizar:false, focalOlt:'',reponseStatusOlt:''})
  };
  ConsultaOnu = (olt) => {
    console.log(olt)
    this.setState({ oltSelect: olt })
    const filtro_param = olt; // Defina o valor do filtro aqui
    const API_URL = `http://138.97.13.78:8000/monitoramento/Onus/?filtro_olt=${filtro_param}`;
    const responseToken = localStorage.getItem('token')
    axios
      .get(API_URL, {
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': 'Token ' + localStorage.getItem('token')
          'Authorization': 'Token ' + responseToken
        },
        credentials: "include",
      })
      .then((response) => {

        console.log(response.data)
        this.setState({ olt: response.data });
      })
      .catch((error) => {
        console.log('aqui')
        console.log(error);
      });
  }

  render() {
    const { data } = this.state;
    const { olt } = this.state;
    if (!data) {
      return (
        <div className="container fluid">
          <div align="center">
            <h1>Intranet </h1>
            <h5>Bem Vindo {this.state.usuario}</h5>
            <h1>Links Externos</h1>
            <a href="https://mk.redebrtelecom.com.br/mk/" target="_blank"><button className="btn btn-primary mr-2" >Mk Solutions</button></a>
            <a href="https://bot.redebrtelecom.com.br/" target="_blank"><button className="btn btn-primary mr-2" >Bot Atendimento</button></a>
            <a href="https://138.97.13.170/" target="_blank"><button className="btn btn-primary mr-2" >Webmail</button></a>
          </div>
        </div>
      )
    }
    return (

      <div>
        <div >
          <button className='onu-n-provisionadas-home' onClick={() => console.log('teste')}>Onus Não Provisionadas (0)</button>
        </div>
        <div style={{ 'display': 'flex', 'flexFlow': 'wrap', 'flexDirection': 'row', 'marginLeft': '1%' }}>
          {data.map((olt) => (
            <div className='me-3'>
            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '270px', 'marginRight': '1%', 'cursor': 'pointer' }} onClick={() => this.ConsultaOnu(olt.nome)}>
              <Card style={{'flex-direction':'row'}}>
                <h5>{olt.nome}</h5>
                <img src={NokiaOlt} style={{ 'width': '30%', 'margin': '0px' }} />
              </Card>
            </div>
            <Button style={{'width':'100%'}} onClick={() => this.handleAtualizarOlt(olt.nome)}>Atualizar</Button>
            </div>
          ))}
          <hr />
        </div>
        <div style={{ 'margin': '1%' }}>
          <TableOnu data={this.state.olt} oltSelect={this.state.oltSelect} />

        </div>
        <Modal show={this.state.oltAtualizar} onHide={this.handleCloseOlt} >
        <Modal.Header closeButton>
          <Modal.Title>Atualizar {this.state.focalOlt}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {this.state.reponseStatusOlt === 'aguarde' && (
            <Alert variant="info">
            <div className="d-flex align-items-center">
              <div className="spinner-border mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              Aguarde Atualizando!
            </div>
          </Alert>
          )}
          {this.state.reponseStatusOlt === 'OK' && (
            <Alert variant="success">
              Atualizado com sucesso!
            </Alert>
          )}
          {this.state.reponseStatusOlt === 'error' && (
            <Alert variant="danger">
              Erro Não foi Atalizado!
            </Alert>
          )}
          <h6 className='mb-3'>Deseja mesmo atualizar os dados da {this.state.focalOlt} </h6>
          <Button variant="secondary" className="mr-3" onClick={this.handleCloseOlt}>
            Fechar
          </Button>

          <Button variant="danger" onClick={() => this.AtualizarOlts(this.state.focalOlt)}>
            Atualizar
          </Button>
        </Modal.Body>
      </Modal>

      </div>
      

    );

  }
}


export default Home