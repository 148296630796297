import React, { Component } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip, Legend } from 'recharts'
import axios from 'axios';
import './BotChats.css'

export class Chats extends Component {
  constructor() {
    super();
    this.state = {
      dataIni: '',
      dataEnd: '',
      data: [],
      departamentos: "",
      chartDados: false
    };
  };

  componentDidMount() {
    console.log(this.props.urlApi)
  }
  async geradorRelatorioChat() {
    var chartDados = true
    var dataIni = this.state.dataIni
    var dataEnd = this.state.dataEnd
    var dadosChat = []
    if (dataIni >= dataEnd) {
      console.log('A data inicial deve ser menor que a data final')
    } else {
      console.log(this.state.dataIni, this.state.dataEnd)
      var postData = {
        dataIni,
        dataEnd
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Token ' + localStorage.getItem('token')
        },
        credentials: "include",
      }
      await axios.post('http://138.97.13.78:8000/consulta/bot/atendimentos/', postData, axiosConfig)
        .then(dados => {
          var chat = dados.data
          this.setState({ data: chat, chartDados })


        })
      // this.setState({data:dadosChat})

    }

  }

  render() {
    return (
      <div className="container">
        <h1>Chats</h1>
        <Form >

          <Row>
            <Col>
              <Form.Label>Data Inicio</Form.Label>
              <Form.Control type="date" placeholder="Inicio" onChange={(e) => this.setState({ dataIni: e.target.value })} />
            </Col>
            <Col>
              <Form.Label>Data Fim</Form.Label>
              <Form.Control type="date" placeholder="Fim" onChange={(e) => this.setState({ dataEnd: e.target.value })} />
            </Col>
          </Row>
          <Button onClick={() => this.geradorRelatorioChat()} >Gerar</Button>
        </Form>
        {this.state.chartDados ? 
        <div className='chartDados'>
        <BarChart width={1250} height={500} data={this.state.data}>
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Atendimentos" fill="#8884d8" />
        </BarChart>
        </div > : <div></div>}
        {/* <RadialBarChart
          width={1300}
          height={800}
          innerRadius="10%"
          outerRadius="80%"
          data={this.state.data}
          startAngle={180}
          endAngle={0}
        >

          <RadialBar minAngle={15} label={{ fill: '#000' }} clockWise={true} background dataKey='Atendimentos' />
          <Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='top' align="right" />
          <Tooltip />
        </RadialBarChart> */}
      </div>
    )
  }
}

export default Chats