import { useEffect, useState } from 'react';
import { Button, Form, Modal, Tab, Table, Tabs } from 'react-bootstrap';
import axios from 'axios'
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';

function TableComponent(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [show, setShow] = useState(false);
  const [ont, setOnt] = useState('')
  const [olt, setOlt] = useState('')
  const [ssid, setSsid] = useState('')
  const [password, setPassword] = useState('')
  const [activeTab, setActiveTab] = useState('ssidpass');
  const [responseStatus, setResponseStatus] = useState('');
  const [loading, setLoading] = useState(false);


  const handleClear = () => {
    // Limpa os estados
    setSsid('');
    setPassword('');
  };

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    handleClear();
    setResponseStatus(''); // Call handleClear on tab change
  }, [activeTab]);

  const handleClose = () => {
    setOnt('')
    setOlt('')
    setSsid('')
    setPassword('')
    setResponseStatus('');
    setShow(false)
  };
  var handleShow = (ontSelecionada, oltSelecionada) => {
    setOnt(ontSelecionada)
    setOlt(oltSelecionada)
    setShow(true);
  }

  const handleOnuShow = (onu) => {
    const API_URL = 'http://138.97.13.78:8000/api-v1/ms/coletorIp/';
    const responseToken = 'ab250b923927823bba30417bb011180d9ee20ae2';
    const payload = { "onu": onu };

    axios
      .post(API_URL, payload, {
        headers: {
          'Authorization': 'Token ' + responseToken,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        window.open('http://' + String(response.data['ip']) + ':8080/', 'Blank');
      })
      .catch(function (error) {
        console.error(error);
      });
  };


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (column) => {
    const direction =
      column === sortColumn && sortDirection === 'asc' ? 'desc' : 'asc';

    setSortColumn(column);
    setSortDirection(direction);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = {
      ssid: ssid,
      password: password,
      ont: ont,
      olt: olt,
    };



    const responseToken = localStorage.getItem('token');

    axios.post('http://138.97.13.78:8000/api-v1/ms/changeWifi/', formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + responseToken
      },
      credentials: 'include'
    })
      .then(response => {
        handleClear();
        setLoading(false);
        setResponseStatus(response.data.status);
        // Faça o processamento necessário com a resposta
      })
      .catch(error => {
        console.error(error);
        // Lida com erros da requisição
      });
  };
  const handleOnuStatus = (onu) => {
    document.getElementById(onu).innerHTML  = `<div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>`
    const formData = {
      pppoe: onu,
      departamento:"SUPORTE TECNICO"
    };

    const responseToken = localStorage.getItem('token');

    axios.post('http://138.97.13.78:8000/api-v1/ms/bot-onu-suporte/', formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + responseToken
      },
      credentials: 'include'
    })
      .then(response => {
        let energia = response.data['energia']
        let inativo = response.data['inativo']
        let disabilitada = response.data['sinal']
        document.getElementById(onu).innerHTML  =   "<div>Energia:" + energia + '<br>Inativo:' + inativo  +'<br>Rompimento: ' + disabilitada + '</div>'
                                                     

      })
      .catch(error => {
        console.error(error);
        // Lida com erros da requisição
      });
  };
  

  const filteredData = props.data.filter((item) => {
    const values = Object.values(item).map((value) =>
      typeof value === 'string' ? value.toLowerCase() : value
    );



    return values.some((value) =>
      String(value).includes(searchTerm.toLowerCase())
    );
  });

  const sortedData = [...filteredData].sort((itemA, itemB) => {
    const valueA = itemA[sortColumn];
    const valueB = itemB[sortColumn];

    if (typeof valueA === 'string') {
      return valueA.localeCompare(valueB) * (sortDirection === 'asc' ? 1 : -1);
    } else if (typeof valueA === 'number') {
      return (valueA - valueB) * (sortDirection === 'asc' ? 1 : -1);
    } else {
      return (
        String(valueA).localeCompare(String(valueB)) *
        (sortDirection === 'asc' ? 1 : -1)
      );
    }
  });

  const countOnlineONUs = props.data.filter(
    (onu) => onu.rx_ont !== 'unknown' && onu.rx_on !== ''
  ).length;

  const countOfflinesONUs = sortedData.filter(
    (onu) => onu.rx_ont === 'unknown' || onu.rx_on === ''
  ).length;

  const countDbOkONUs = sortedData.filter(
    (onu) => onu.rx_ont > -27
  ).length;

  const countDbNokONUs = sortedData.filter(
    (onu) => onu.rx_ont < -27
  ).length;

  return (
    <>
      <div style={{ 'marginTop': '2%', 'display': 'flex', 'justifyContent': 'space-evenly', 'marginBottom': '-4%' }}>
        <Button variant="success" style={{ 'width': '100%' }}>Online ({countOnlineONUs})</Button>
        <Button variant="danger" style={{ 'width': '100%' }}>Offline ({countOfflinesONUs})</Button>
        <Button variant="success" style={{ 'width': '100%' }}>Db Bom ({countDbOkONUs})</Button>
        <Button variant="warning" style={{ 'width': '100%' }}>Atenuados ({countDbNokONUs})</Button>
      </div>
      <Table style={{ marginTop: '5%' }}>
        <input
          type="text"
          placeholder="Filtrar"
          value={searchTerm}
          onChange={handleSearch}
        />
        <thead>
          <tr>
            <th onClick={() => handleSort('onts')}>
              LOC
              {sortColumn === 'onts' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('rx_ont')}>
              ONU
              {sortColumn === 'rx_ont' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('Rx_Olt')}>
              OLT
              {sortColumn === 'Rx_Olt' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('temp_ont')}>
              TEMP
              {sortColumn === 'temp_ont' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('serial')}>
              SERIAL
              {sortColumn === 'serial' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('modelo')}>
              PPPOE
              {sortColumn === 'modelo' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('descricao')}>
              Descrição
              {sortColumn === 'descricao' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('cto')}>
              CTO
              {sortColumn === 'cto' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('bloqueado')}>
              Bloqueado
              {sortColumn === 'bloqueado' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th onClick={() => handleSort('horarioQueda')}>
              Ult. Queda
              {sortColumn === 'horarioQueda' && (
                <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
              )}
            </th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((onu) => (
            <tr key={onu.id} id={onu.onts} style={{ backgroundColor: onu.rx_ont < -27 ? 'orange' : onu.rx_ont === 'unknown' ? 'red' : 'white',
            color: onu.rx_ont < -27 ? 'white' : onu.rx_ont === 'unknown' ? 'white' : 'black' }}>
              <td>{onu.onts}</td>
              <td>{onu.rx_ont}</td>
              <td>{onu.Rx_Olt}</td>
              <td>{onu.temp_ont}</td>
              <td>{onu.serial}</td>
              <td>{onu.modelo}</td>
              <td>{onu.descricao}</td>
              <td>{onu.cto}</td>
              <td>{onu.bloqueado}</td>
              <td>{onu.horarioQueda}</td>
              <td>
              <Button onClick={() => handleOnuStatus(onu.modelo)} className='mr-3' variant='warning'>Atualizar</Button>
                <Button onClick={() => handleOnuShow(onu.modelo)} className='mr-3' variant='success'>Acessar</Button>
                <Button onClick={() => handleShow(onu.onts, onu.Olt)} className='mr-3'>TrocaWifi</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Troca de Wifi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="ssidpass"
            activeKey={activeTab}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={handleTabSelect}
          >
            <Tab eventKey="ssidpass" title="SSID + PASS" >
              <div>
                {responseStatus === 'OK' && (
                  <Alert variant="success">
                    Modificado com sucesso!
                  </Alert>
                )}
                {responseStatus === 'error' && (
                  <Alert variant="danger">
                    Erro Não foi Modificado!
                  </Alert>
                )}

                <Form>
                  <div align="center">{ont} - {olt}</div>
                  <Form.Group className="mb-3" controlId="somente o nome">
                    <Form.Label>SSID</Form.Label>
                    <Form.Control type="text" placeholder="somente o nome" value={ssid} onChange={(e) => setSsid(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>PASSWORD</Form.Label>
                    <Form.Control type="text" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Button variant="secondary" className="mr-3" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                      Salvar
                    </Button>
                    {loading === true && (
                      <div class="spinner-border text-primary ml-3" role="status" style={{ 'vertical-align': 'middle' }}>
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </Form.Group>
                </Form>
              </div>


            </Tab>
            <Tab eventKey="ssid" title="SSID">
              {responseStatus === 'OK' && (
                <Alert variant="success">
                  Modificado com sucesso!
                </Alert>
              )}
              {responseStatus === 'error' && (
                <Alert variant="danger">
                  Erro Não foi Modificado!
                </Alert>
              )}

              <div>
                <Form>
                  <Form.Group className="mb-3" controlId="somente o nome">
                    <Form.Label>SSID</Form.Label>
                    <Form.Control type="text" placeholder="somente o nome" value={ssid} onChange={(e) => setSsid(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Button variant="secondary" className="mr-3" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                      Salvar
                    </Button>
                    {loading === true && (
                      <div class="spinner-border text-primary ml-3" role="status" style={{ 'vertical-align': 'middle' }}>
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </Form.Group>
                </Form>
              </div>
            </Tab>
            <Tab eventKey="pass" title="PASS" >
              {responseStatus === 'OK' && (
                <Alert variant="success">
                  Modificado com sucesso!
                </Alert>
              )}
              {responseStatus === 'error' && (
                <Alert variant="danger">
                  Erro Não foi Modificado!
                </Alert>
              )}

              <div>
                <Form>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>PASSWORD</Form.Label>
                    <Form.Control type="text" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="password">
                    <Button variant="secondary" className="mr-3" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                      Salvar
                    </Button>
                    {loading === true && (
                      <div class="spinner-border text-primary ml-3" role="status" style={{ 'vertical-align': 'middle' }}>
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </Form.Group>
                </Form>
              </div>
            </Tab>
          </Tabs>

        </Modal.Body>



      </Modal>
    </>
  );
}

export default TableComponent;