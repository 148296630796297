import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
export class TrocaSenha extends Component {
  constructor() {
    super();

    this.state = {
      username: localStorage.getItem('username'),
      SN:"",
      RSN:"",
    };
  }

  trocarSenha = () => {
      if(this.state.SN === this.state.RSN){
          console.log('trocada')
      }
  }
  render() {
    return (
      <div className="container fluid">
        <div align="center">
          <h1>Trocar Senha </h1>
          <Form>
            
            <div className='mb-3'>
              <input type="password" placeholder='Senha Atual' />
            </div>
            <div className='mb-3'>
              <input type="password" placeholder='Senha Nova' onChange={(e) => this.setState({SN:e.target.value})} />
            </div>
            <div className='mb-3'>
              <input type="password" placeholder='Repita Senha Nova' onChange={(e) => this.setState({RSN:e.target.value})}/>
            </div>
            <Button onClick={() => {this.trocarSenha()}}>Trocar</Button>
          </Form>
        </div>
      </div>
    )
  }
}

export default TrocaSenha