import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import React, { useState, useContext, createContext, useEffect } from 'react'
import Login from './view/login/Login'
import Home from './view/Home/HomeSn1'
import HomeSn2 from './view/Home/HomeSn2'
import Alarmes from './view/Monitoramento/Alarmes/Alarmes'
import AlarmDetails from './view/Monitoramento/AlarmDetails/AlarmDetails'
import Relatorios from './view/Financeiro/Relatorios/Relatorios/Relatorios'
import Menu from './components/menu/Menu'
import BotChats from './view/Relatorios/Bot/BotChats/BotChats'
import BotDepartamentos from './view/Relatorios/Bot/BotDepartamentos/BotDepartamentos'
import SemViabilidade from './view/Comercial/Lead/SemViabilidade/SemViabilidade'
import FIN_Whatsapp from './view/Financeiro/Cobranca/Whatsapp/Whatsapp'
import IndiqueUmAmigo from './view/Comercial/IndiqueUmAmigo/IndiqueUmAmigo'
import Parceiro from './view/Comercial/Parceiro/Parceiro'
import CadastroFonecedor from './view/Fornecedores/Cadastro/Cadastro'
import TrocaSenha from './view/TrocaSenha/TrocaSenha'
import SmsMassa from './view/Microservicos/Sms/SmsMassa'
import DetalhesChats from './view/Relatorios/Bot/ChatAcompanhamento/BotAcompanhamento'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [setor, setSetor] = useState('');
  const [cargo, setCargo] = useState('');


  const loginHandle = () => {
    localStorage.getItem('token');

    setSetor(localStorage.getItem('setor'))
    setCargo(localStorage.getItem('cargo'))
  }
  const getSession = () => {
    var token = localStorage.getItem('token');
    setSetor(localStorage.getItem('setor'))
    setCargo(localStorage.getItem('cargo'))

    if (token) {
      setIsAuthenticated(true);
    }
  }
  useEffect(() => {
    localStorage.setItem('urlAPI', 'http://138.97.13.78:8000/');
    getSession();
  }, []);

  


  if (!isAuthenticated) {
    return (
      <Router>
        <Routes>
          <Route path='*' element={<Login />} loginHandle={loginHandle}/>
        </Routes>
      </Router>
    );
  }
  if (setor === 'COMERCIAL' && cargo === 'COLABORADOR') {
    return (
      <Router>
        <Menu />
        <Routes>
          <Route path='/comercial/parceiro/' element={<Parceiro />} />
          <Route path='/comercial/indiqueumamigo/' element={<IndiqueUmAmigo />} />
          <Route path='/comercial/lead/semviabilidade/' element={<SemViabilidade />} />
          <Route path='/trocasenha/' element={<TrocaSenha />} />
          <Route path='/' element={<Home />} />
        </Routes>

      </Router>
    );
  }
  else if (setor === 'SUPORTE TECNICO - SN1' && cargo === 'COLABORADOR') {
    return (

      <Router>
        <Menu />

        <Routes>
          <Route path='/trocasenha/' element={<TrocaSenha />} />
          <Route path='/' element={<Home />} />
        </Routes>

      </Router>
    );
  }
  else if (setor === 'SUPORTE TECNICO - SN2' && cargo === 'COLABORADOR') {
    return (

      <Router>
        <Menu />

        <Routes>
          <Route path='/trocasenha/' element={<TrocaSenha />} />
          <Route path='/' element={<HomeSn2 />} />
        </Routes>

      </Router>
    );
  }
  else if (cargo === 'ADMINISTRADOR') {
    return (

      <Router>
        <Menu />

        <Routes>
          <Route path='/fornecedores/cadastro/' element={<CadastroFonecedor />} />
          <Route path='/comercial/parceiro/' element={<Parceiro />} />
          <Route path='/comercial/indiqueumamigo/' element={<IndiqueUmAmigo />} />
          <Route path='/comercial/lead/semviabilidade/' element={<SemViabilidade />} />
          <Route path='/relatorios/bot/chat/' element={<BotChats />} />
          <Route path='/relatorios/bot/chat/detalheschats/' element={<DetalhesChats />} />
          <Route path='/relatorios/bot/chat/departamentos/' element={<BotDepartamentos />} />
          <Route path='/financeiro/relatorios/' element={<Relatorios />} />
          <Route path='/financeiro/cobranca/whatsapp/' element={<FIN_Whatsapp />} />
          <Route path='/microservicos/sms/massa/' element={<SmsMassa />} />
          <Route path='/alarmdetails/:olt/:id/' element={<AlarmDetails />} />
          <Route path='/alarmes' element={<Alarmes />} />
          <Route path='/trocasenha/' element={<TrocaSenha />} />
          <Route path='/' element={<HomeSn2 />} />
        </Routes>

      </Router>
    );
  }
}

export default App