import React, { Component } from 'react'
import axios from "axios";
import Table from "react-bootstrap/Table";
import NokiaOlt from './oltNokia.png'
import { Card, Col, Row } from 'react-bootstrap';
import TableOnu from '../../components/table_onus/TableSn1'

export class Home extends Component {
  constructor() {
    super();

    this.state = {
      usuario: "",
      data: [],
      olt:[],
    };
  }

  componentDidMount() {
    const API_URL = "http://138.97.13.78:8000/monitoramento/Olts/";
    const responseToken = localStorage.getItem('token')
    axios
      .get(API_URL, {
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': 'Token ' + localStorage.getItem('token')
          'Authorization': 'Token ' + responseToken
        },
        credentials: "include",
      })
      .then((response) => {

        console.log(response.data.results)
        this.setState({ data: response.data.results });
      })
      .catch((error) => {
        console.log('aqui')
        console.log(error);
      });
  }
  
  ConsultaOnu = (olt) => {
    console.log(olt)
    const filtro_param = olt; // Defina o valor do filtro aqui
    const API_URL = `http://138.97.13.78:8000/monitoramento/Onus/?filtro_olt=${filtro_param}`;
    const responseToken = localStorage.getItem('token')
    axios
      .get(API_URL, {
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': 'Token ' + localStorage.getItem('token')
          'Authorization': 'Token ' + responseToken
        },
        credentials: "include",
      })
      .then((response) => {

        console.log(response.data)
        this.setState({ olt: response.data });
      })
      .catch((error) => {
        console.log('aqui')
        console.log(error);
      });
  }

  render() {
    const { data } = this.state;
    const { olt } = this.state;
    if (!data) {
      return (
        <div className="container fluid">
          <div align="center">
            <h1>Intranet </h1>
            <h5>Bem Vindo {this.state.usuario}</h5>
            <h1>Links Externos</h1>
            <a href="https://mk.redebrtelecom.com.br/mk/" target="_blank"><button className="btn btn-primary mr-2" >Mk Solutions</button></a>
            <a href="https://bot.redebrtelecom.com.br/" target="_blank"><button className="btn btn-primary mr-2" >Bot Atendimento</button></a>
            <a href="https://138.97.13.170/" target="_blank"><button className="btn btn-primary mr-2" >Webmail</button></a>
          </div>
        </div>
      )
    }
    return (

      <div>

        <div style={{ 'display': 'flex', 'flexFlow': 'wrap', 'flexDirection': 'row', 'marginLeft': '1%' }}>
          {data.map((olt) => (
            <div style={{ 'display': 'flex', 'flexDirection': 'row', 'width': '270px', 'marginRight': '1%', 'cursor': 'pointer' }} onClick={() => this.ConsultaOnu(olt.nome)}>
              <Card style={{'flex-direction':'row'}}>
                <h5>{olt.nome}</h5>
                <img src={NokiaOlt} style={{ 'width': '30%', 'margin': '0px' }} />
              </Card>
            </div>
          ))}
          <hr />
        </div>
        <div style={{  'margin': '1%' }}>
          <TableOnu data={this.state.olt}/>
          
        </div>

      </div>

    );

  }
}


export default Home