import React, { Component } from 'react'
import { Container, Table, Button } from 'react-bootstrap'
import axios from 'axios'

class SemViabilidade extends Component {
    constructor() {
        super();
        this.state = {
            LeadSemViabilidade:[],
        }
    }



    componentDidMount() {
        this.buscaLeadSemViabilidade();
    }


    async buscaLeadSemViabilidade() {
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                // 'Authorization': 'Token ' + localStorage.getItem('token')
                'Authorization': 'Token ' + localStorage.getItem('token')
            },
            credentials: "include",
        }
        await axios.get('http://138.97.13.78:8000/comercial/lead/', axiosConfig)
            .then(data => {
                // handle success

                console.log(data.data)
                this.setState({ LeadSemViabilidade: data.data.results})
                


            })
    }

    finalizar = async (id) =>{
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                // 'Authorization': 'Token ' + localStorage.getItem('token')
                'Authorization': 'Token ' + localStorage.getItem('token')
            },
            credentials: "include",
        }
        await axios.delete('http://138.97.13.78:8000/comercial/lead/' + id +'/' ,axiosConfig)
            .then(data => {
                window.location.href = '/comercial/lead/semviabilidade/'
    })
}

    render() {
        return (
            <Container fluid>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>Nome</th>
                            <th>Cpf</th>
                            <th>Nascimento</th>
                            <th>Email</th>
                            <th>whatsapp</th>
                            <th>Tel</th>
                            <th>Cep</th>
                            <th>Logradouro</th>
                            <th>Bairro</th>
                            <th>Cidade</th>
                            <th>Numero</th>
                            <th>Complemento</th>
                            <th>Vencimento</th>
                            <th>Velocidade</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.LeadSemViabilidade.map((itens, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{itens.nome}</td>
                                            <td>{itens.cpf}</td>
                                            <td>{itens.nascimento}</td>
                                            <td>{itens.email}</td>
                                            <td>{itens.whatsapp}</td>
                                            <td>{itens.tel}</td>
                                            <td>{itens.cep}</td>
                                            <td>{itens.logradouro}</td>
                                            <td>{itens.bairro}</td>
                                            <td>{itens.cidade}</td>
                                            <td>{itens.numero}</td>
                                            <td>{itens.complemento}</td>
                                            <td>{itens.vencimento}</td>
                                            <td>{itens.velocidade}</td>
                                            <td><Button onClick={ () =>this.finalizar(itens.id)}>Finalizar</Button></td>
                                        </tr>
                                    ))}
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default SemViabilidade