import React from 'react'
import { DropdownButton, Dropdown, Col } from 'react-bootstrap'

export default function fornecedores() {
    return (
        <Col>
            <DropdownButton className='custom-dropdown'
                key='down'
                id={`dropdown-button-drop-down`}
                drop='down'
                variant="secondary"
                title='Fornecedores'
            >
                <Dropdown.Item href="/fornecedores/cadastro/">Cadastro</Dropdown.Item>
                <Dropdown.Item href="/fornecedores/envioCotacao/">Envio de Cotação</Dropdown.Item>
            </DropdownButton>
        </Col>
    )
}
