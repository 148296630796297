import React, { Component } from 'react'
import './Alarmes.css'
import { Table, Card } from 'react-bootstrap';
import axios from 'axios';



export class Alarmes extends Component {

    

//Tullio Rohers Rauber
    constructor() {
        super();
    
        this.state = {
          pqp: "",
          bm: "",
          ja: "",
          nc: "",
          x1: "",
          x2: "",
          pqpt: [],
          bmt: [],
          jat: [],
          nct: [],
          x1t: [],
          x2t: [],
        };
      }
    

    componentDidMount = () => {
        this.alarmesTotais();
        this.alarmesMonitor();
        setInterval(() => {
            this.alarmesTotais();
            this.alarmesMonitor();
        }, 5000);
        
    }

    

    alarmesTotais = () => {
        axios.get('http://138.97.13.78:8000/alarmes/', {
            headers: {
              "Content-Type": "application/json",
              'Authorization':'Token ' + localStorage.getItem('token')
            },
            credentials: "include",
          })
  .then(data => {
    // handle success
    this.setState({pqp:data.data['AlarmesTotais'][0]['pqp'],
    bm:data.data['AlarmesTotais'][1]['bm'],
    ja:data.data['AlarmesTotais'][2]['ja'],
    nc:data.data['AlarmesTotais'][3]['nc'],
    x1:data.data['AlarmesTotais'][4]['x1'],
    x2:data.data['AlarmesTotais'][5]['x2']})
  })
    }
    
   alarmesMonitor = async() => {
        await axios.get('http://138.97.13.78:8000/alarmes-monitor/',{
            headers: {
              "Content-Type": "application/json",
              'Authorization':'Token ' + localStorage.getItem('token')
            },
            credentials: "include",
          }
        )
        .then(data => {
            // handle success
           //console.log(data.data['alarmes'])
           var dados = data.data['alarmes']
           //console.log(dados.length)
           for(var i = 0; i <= dados.length;i++){
               
            //console.log(dados[i])
            if(i === 0){

                this.setState({pqpt:dados[0]['pqp']})
            }else if(i === 1){
                this.setState({nct:dados[1]['nc']})
            }
            else if(i === 2){
                this.setState({bmt:dados[2]['bm']})
            }
            else if(i === 3){
                this.setState({jat:dados[3]['ja']})
            }
            else if(i === 4){
                this.setState({x1t:dados[4]['x1']})
            }
            else if(i === 5){
                this.setState({x2t:dados[5]['x2']})
            }
            }
            
          })
    }

    
    handleClick= (id) => {
        console.log(id)
       }
       

    render() {
        return (
            <div className="containerAlarme">
                <div className='container-fluid'>
                    <div className='row' align="center">
                        <div className='col-2'>
                            <Card>
                            <h1>Parque Paulista - {this.state.pqp}</h1>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ONU</th>
                                        <th>Alarmes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.pqpt.map(itens => (
                                   <tr onClick={() => window.location.href = "/alarmdetails/pqp/"+itens.id+"/"}>
                                        <td>{itens.quantidade}</td>
                                        <td>{itens.alarm}</td>
                                    </tr>
                                    ))}
                                </tbody>
                            </Table>
                            </Card>
                        </div>
                        <div className='col-2'>
                        <Card>
                        <h1>Beira Mar - {this.state.bm}</h1>

                        <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ONU</th>
                                        <th>Alarmes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.bmt.map(itens => (
                                   
                                    <tr onClick={() => window.location.href = "/alarmdetails/bm/"+itens.id+"/"}>
                                        <td>{itens.quantidade}</td>
                                        <td>{itens.alarm}</td>
                                    </tr>
                              
                                    ))}
                                </tbody>
                            </Table>
                            </Card>
                        </div>
                        <div className='col-2'>
                        <Card>
                        <h1>Jardim Anhanga - {this.state.ja}</h1>

                        <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ONU</th>
                                        <th>Alarmes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.jat.map(itens => (
                                    
                                    <tr onClick={() => window.location.href = "/alarmdetails/ja/"+itens.id+"/"}>
                                        <td>{itens.quantidade}</td>
                                        <td>{itens.alarm}</td>
                                    </tr>
                                 
                                    ))}
                                </tbody>
                            </Table>
                            </Card>
                        </div>
                        <div className='col-2'>
                        <Card>
                        <h1>Nova Campina - {this.state.nc}</h1>

                        <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ONU</th>
                                        <th>Alarmes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.nct.map(itens => (
                                    <tr onClick={() => window.location.href = "/alarmdetails/nc/"+itens.id+"/"}>
                                        <td>{itens.quantidade}</td>
                                        <td>{itens.alarm}</td>
                                    </tr>
                                    ))}
                                </tbody>
                            </Table >
                            </Card>
                        </div>
                        <div className='col-2'>
                        <Card>
                        <h1>Xerem 1 - {this.state.x1}</h1>

                        <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ONU</th>
                                        <th>Alarmes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.x1t.map(itens => (
                                    <tr onClick={() => window.location.href = "/alarmdetails/x1/"+itens.id+"/"}>
                                        <td>{itens.quantidade}</td>
                                        <td>{itens.alarm}</td>
                                    </tr>
                                    ))}
                                </tbody>
                            </Table>
                            </Card>
                        </div>
                        <div className='col-2'>
                        <Card>
                        <h1>Xerem 2 - {this.state.x2}</h1>

                        <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ONU</th>
                                        <th>Alarmes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.x2t.map(itens => (
                                    <tr onClick={() => window.location.href = "/alarmdetails/x2/"+itens.id+"/"}>
                                        <td>{itens.quantidade}</td>
                                        <td>{itens.alarm}</td>
                                    </tr>
                                    ))}
                                </tbody>
                            </Table>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Alarmes