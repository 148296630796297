import React, { Component } from 'react'
import { Container, Table, Button } from 'react-bootstrap'
import axios from 'axios'

class IndiqueUmAmigo extends Component {
    constructor() {
        super();
        this.state = {
            LeadSemViabilidade:[],
        }
    }



    componentDidMount() {
        this.buscaLeadSemViabilidade();
    }


    async buscaLeadSemViabilidade() {
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                // 'Authorization': 'Token ' + localStorage.getItem('token')
                'Authorization': 'Token ' + localStorage.getItem('token')
            },
            credentials: "include",
        }
        await axios.get('http://138.97.13.78:8000/comercial/indiqueumamigo/', axiosConfig)
            .then(data => {
                // handle success

                console.log(data.data)
                this.setState({ LeadSemViabilidade: data.data.results})
                


            })
    }

    finalizar = async (id) =>{
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                // 'Authorization': 'Token ' + localStorage.getItem('token')
                'Authorization': 'Token ' + localStorage.getItem('token')
            },
            credentials: "include",
        }
        await axios.patch('http://138.97.13.78:8000/comercial/indiqueumamigo/' + id +'/', {ativa:false} ,axiosConfig)
            .then(data => {
                window.location.href = '/comercial/indiqueumamigo/'
    })
}
    render() {
        return (
            <Container fluid>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>Cliente Nome</th>
                            <th>Cliente Whatsapp</th>
                            <th>Cliente CPF</th>
                            <th>Amigo Nome</th>
                            <th>Amigo Whatsapp</th>
                            <th>Amigo CPF</th>
                            <th>Amigo Endereço</th>
                            <th>Data</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.LeadSemViabilidade.map((itens, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{itens.cliente_nome}</td>
                                            <td>{itens.cliente_whatsapp}</td>
                                            <td>{itens.cliente_cpf}</td>
                                            <td>{itens.amigo_nome}</td>
                                            <td>{itens.amigo_whatsapp}</td>
                                            <td>{itens.amigo_cpf}</td>
                                            <td>{itens.amigo_endereco}</td>
                                            <td>{itens.at_create}</td>
                                            <td><Button onClick={ () =>this.finalizar(itens.id)}>Finalizar</Button></td>
                                        </tr>
                                    ))}
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default IndiqueUmAmigo