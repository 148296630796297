import React from 'react'
import { DropdownButton, Dropdown, Col } from 'react-bootstrap'

export default function financeiro() {
    return (
        <Col>
            <DropdownButton className='custom-dropdown'
                key='down'
                id={`dropdown-button-drop-down`}
                drop='down'
                variant="secondary"
                title='Financeiro'
            >
                <DropdownButton className='custom-dropdown'
                    key='end'
                    id={`dropdown-button-drop-end`}
                    drop='end'
                    variant="secondary"
                    title='Cobranca'
                >
                    <Dropdown.Item href="/financeiro/cobranca/whatsapp/">Whatsapp</Dropdown.Item>
                </DropdownButton>
                <Dropdown.Item href="#">Contratos</Dropdown.Item>
                <Dropdown.Item href="#">Notas Fiscais</Dropdown.Item>
            </DropdownButton>
        </Col>
    )
}
