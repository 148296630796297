import React from 'react'
import { DropdownButton, Dropdown, Col } from 'react-bootstrap'

export default function chamados() {
    return (
        <Col>
            <DropdownButton className='custom-dropdown'
                key='down'
                id={`dropdown-button-drop-down`}
                drop='down'
                variant="secondary"
                title='Chamados'
            >
                <Dropdown.Item href="#">Novo</Dropdown.Item>
                <Dropdown.Item href="#">Consultar</Dropdown.Item>
            </DropdownButton>
        </Col>
    )
}
