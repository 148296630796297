import React from 'react'
import axios from 'axios'

async function Permissions() {
    let axiosConfig2 = {
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': 'Token ' + localStorage.getItem('token')
          'Authorization': 'Token ' + 'ab250b923927823bba30417bb011180d9ee20ae2'
        },
        credentials: "include",
        
      }
    await axios.post('http://138.97.13.78:8000/usuarioInfo/', { "username": localStorage.getItem('username') }, axiosConfig2)
        .then(data => {
              localStorage.setItem('setor', data.data.setor)
              localStorage.setItem('cargo', data.data.cargo)
              return true
        })
        .catch((err) => {
          //console.log(err);
          console.log(err);
        });
}

export default Permissions