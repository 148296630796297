import React from 'react'
import { DropdownButton, Dropdown, Col } from 'react-bootstrap'

export default function gerenciaRedes() {
    return (
        <Col>
            <DropdownButton className='custom-dropdown'
                key='down'
                id={`dropdown-button-drop-down`}
                drop='down'
                variant="secondary"
                title='Gerencia de Rede'
            >
                <Dropdown.Item href="#">Clientes Offline</Dropdown.Item>
                <Dropdown.Item href="#">Comparação de Infra</Dropdown.Item>
                <Dropdown.Item href="#">Diagnóstico do Cliente</Dropdown.Item>
                <Dropdown.Item href="/alarmes/">Monitoramento de Alarmes</Dropdown.Item>
                <Dropdown.Item href="#">Monitoramento de OS</Dropdown.Item>
            </DropdownButton>
        </Col>
    )
}
