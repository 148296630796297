import React from 'react'
import { DropdownButton, Dropdown, Col } from 'react-bootstrap'

export default function relatorios() {
    return (
        <Col>
            <DropdownButton className='custom-dropdown'
                key='down'
                id={`dropdown-button-drop-down`}
                drop='down'
                variant="secondary"
                title='Relatorios'
            >
                <DropdownButton className='custom-dropdown'
                    key='end'
                    id={`dropdown-button-drop-end`}
                    drop='end'
                    variant="secondary"
                    title='Bot'
                >
                    <Dropdown.Item href="/relatorios/bot/chat/">Chat</Dropdown.Item>
                    <Dropdown.Item href="/relatorios/bot/chat/detalheschats/">Chat Detalhes</Dropdown.Item>
                    <Dropdown.Item href="/relatorios/bot/chat/departamentos/">Departamentos</Dropdown.Item>
                </DropdownButton>
            </DropdownButton>
        </Col>
    )
}
