import React from 'react'
import { DropdownButton, Dropdown, Col } from 'react-bootstrap'
import Avatar from 'react-avatar';

function logout() {
    localStorage.removeItem('token')
    this.setState({ isAuthenticated: false })
  };
  
export default function avatar() {
    var username = localStorage.getItem('username')

    return (
        <Col align="right">
            <DropdownButton className='custom-dropdown '
                key='down'
                id={`dropdown-button-drop-down`}
                drop='down'
                variant="secondary"
                title={<Avatar name={username} />}
            >
                <Dropdown.Item href="/trocaSenha/">Trocar de senha</Dropdown.Item>
                <Dropdown.Item onClick={logout} href="/">Logout</Dropdown.Item>
            </DropdownButton>
        </Col>
    )
}
