import React, { useState, useEffect } from 'react';
import Logo from './img/logo.png';
import axios from 'axios';
import Permission from '../../scripts/Permissions';
import './Login.css';

function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');


  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleUserNameChange = (event) => {
    setUsername(event.target.value);
  };

  const login = async (event) => {
    event.preventDefault();
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };

    try {
      const response = await axios.post('http://138.97.13.78:8000/api-token-auth/', {
        username: username,
        password: password,
      }, axiosConfig);

      var token = response.data.token;
      localStorage.setItem('token', token);
      localStorage.setItem('username', username);
      setUsername('');
      setPassword('');
      setError('');
      Permission();
      setTimeout(() => {
        window.location = "/"
      }, 500);
    } catch (err) {
      setError('Usuário e/ou senha inválidos.');
    }
  };

  return (
    <div>
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-5">
              <h2 className="heading-section"> </h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="wrap d-md-flex">
                <div className="img ">
                  <img className="img-login" src={Logo} alt="Logo" />
                </div>
                <div className="login-wrap p-4 p-md-5">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Intranet</h3>
                    </div>
                    <div className="w-100">
                      <p className="social-media d-flex justify-content-end">
                        <a href="#" className="social-icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-facebook"></span>
                        </a>
                        <a href="#" className="social-icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-twitter"></span>
                        </a>
                      </p>
                    </div>
                  </div>
                  <form onSubmit={login} className="signin-form">
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="name">
                        E-mail
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="E-mail"
                        required
                        value={username}
                        onChange={handleUserNameChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="label" htmlFor="password">
                        Senha
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Senha"
                        required
                        value={password}
                        onChange={handlePasswordChange}
                      />
                    </div>
                    <div>
                      {error && (
                        <small className="text-danger">{error}</small>
                      )}
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="form-control btn btn-primary rounded submit px-3"
                      >
                        Entrar
                      </button>
                    </div>
                    <div className="form-group d-md-flex"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;