import React, { Component } from 'react'
import './style.css'

export class BotAcompanhamento extends Component {
  render() {
    return (
      <main>
        <div className='container'>
          <div className='row'>
            <div className='col-4'>
              <div className='card'>
                <h2>BOT</h2>
                <hr />
                <h1>Abertos: 0</h1>
                <h1>Fechados: 0</h1>
                <div>
                  <a href='#'>Detalhes</a>
                </div>
                <hr />
              </div>
            </div>
            

          </div>
        </div>
      </main>
    )
  }
}

export default BotAcompanhamento