import React, { Component } from 'react'
import './AlarmDetails.css'
import { Table, Spinner } from 'react-bootstrap';
import axios from 'axios';



export class AlarmDetails extends Component {

    //Tullio Rohers Rauber
    constructor() {
        super();

        this.state = {
            alarmesList: [],
            olt: "",
            alarmId: "",
            titleOlt: "",
            loading: [<div className="loading" align="center">
            <Spinner animation="grow" variant="primary"/>
            </div>]

        };
    }

    componentDidMount = () => {

        this.AlarmesDetails();
    }
    componentWillMount = () => {
        this.extrairUrl();
    }
    extrairUrl = () => {
        this.setState({
            olt: String(window.location.pathname).split('/')[2].split('/')[0],
            alarmId: String(window.location.pathname).split('/')[3].split('/')[0],
        })
        
    }




    AlarmesDetails = async () => {
        //console.log(localStorage.getItem('token'))
        if (this.state.olt === 'pqp') {
            this.setState({ titleOlt: 'Parque Paulista' })
        }else if (this.state.olt === 'bm') {
            this.setState({ titleOlt: 'Beira Mar' })


        } else if (this.state.olt === 'ja') {
            this.setState({ titleOlt: 'Jardim Anhangá' })


        } else if (this.state.olt === 'nc') {
            this.setState({ titleOlt: 'Nova Campina' })


        } else if (this.state.olt === 'x1') {
            this.setState({ titleOlt: 'Xerém 1' })


        } else if (this.state.olt === 'x2') {
            this.setState({ titleOlt: 'Xerém 2' })

        }
        var postData = {
            olt: this.state.olt,
            alarm: this.state.alarmId
        };

        let axiosConfig = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Token ' + localStorage.getItem('token')
            },
            credentials: "include",
        }
        await axios.post('http://138.97.13.78:8000/alarmesDetails/', postData, axiosConfig)
            .then(data => {
                // handle success


                this.setState({ alarmesList: data.data,
                                loading:[] })
                


            })

    }

    render() {
        return (
            <div className="containerAlarme">
                <div className='container-fluid'>
                    <div className='row' align="center">
                        <div className='col'>
                            <h1>{this.state.titleOlt}</h1>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ONU</th>
                                        <th>Alarme</th>
                                        <th>Horário</th>
                                        <th>ONU Temp</th>
                                        <th>ONU Atenuação</th>
                                        <th>OLT Atenuação</th>
                                        <th>Username</th>
                                    </tr>
                                </thead>
                               
                                <tbody>
                                    {this.state.alarmesList.map(itens => (
                                        <tr>
                                            <td>{itens.client}</td>
                                            <td>{itens.alarm}</td>
                                            <td>{itens.horario}</td>
                                            <td>{itens.temp}</td>
                                            <td>{itens.atOnu}</td>
                                            <td>{itens.atOlt}</td>
                                            <td>{itens.username}</td>

                                        </tr>
                                    ))}
                                </tbody>
                                
                            </Table>
                           {this.state.loading}
                        </div>

                    </div>
                </div>
            </div>

        )
    }
}

export default AlarmDetails