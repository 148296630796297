import React from 'react'
import './Menu.css'
import { Button, Container, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Avatar from 'react-avatar';
import Permission from '../../scripts/Permissions'
import Comercial from './comercial'
import AvatarMenu from './avatar'
import Atendimentos from './atendimentos'
import Chamados from './chamados'
import Finaneiro from './financeiro'
import GerenciaRedes from './gerenciaRedes'
import Relatorios from './relatorios'
import Fornecedores from './fornecedores'
import Microservicos from './microservicos'
import Rh from './rh'
import LogoMk from './imagens/mk.png'
import LogoWebmail from './imagens/webmail.png'

const menuItems = [
  {
    link:"https://mk.redebrtelecom.com.br/mk",
    image:"Mk",
    tooltipText:"Mk Solutions",
  },
  {
    link:"https://webmail-seguro.com.br/",
    image:"Webmail",
    tooltipText:"Webmail",
  },
  {
    link: 'https://bi.redebrtelecom.com.br/',
    image: 'BI',
    tooltipText: 'BI',
  },
  {
    link: 'http://nuvem.redebrtelecom.com.br/',
    image: 'Coud',
    tooltipText: 'Coud',
  },
  {
    link: 'https://awsbot.redebrtelecom.com.br/',
    image: 'Bot',
    tooltipText: 'Bot',
  },
  {
    link: 'https://redebrtelecom.zohodesk.com/portal/pt/kb/redebr-telecom',
    image: 'Zoho',
    tooltipText: 'Zoho',
  },
  {
    link: 'https://redebr.myuc2b.com/',
    image: 'Telefonia',
    tooltipText: 'Telefonia PABX',
  },
  {
    link: 'https://mvno.tipbrasil.com.br/',
    image: 'Movel',
    tooltipText: 'Movel',
  },
];

function MenuItemWithTooltip({ link, image, tooltipText }) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipText}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      <a href={link} target="_blank">
        <button className={`ButtonLogo${image}`} />
      </a>
    </OverlayTrigger>
  );
}

function Menu() {
  Permission();
  if (localStorage.getItem('setor') === 'COMERCIAL' && localStorage.getItem('cargo') === 'COLABORADOR') {
    return (
      <>
        <Container fluid>
          <Row>
            <Comercial />
            <AvatarMenu />
            <hr ></hr>
          </Row>
        </Container>
      </>
    )
  }
  else if (localStorage.getItem('setor') === 'SUPORTE TECNICO - SN1' && localStorage.getItem('cargo') === 'COLABORADOR') {
    return (
      <>
        <Container fluid>
          <Row>
            <GerenciaRedes />
            <AvatarMenu />
            <hr ></hr>
          </Row>
        </Container>
      </>
    )
  }
  else {
    return (
      <>
        <Container fluid>
          <Row>
            <Atendimentos />
            <Chamados />
            <Comercial />
            <Finaneiro />
            <GerenciaRedes />
            <Relatorios />
            <Microservicos />
            <Fornecedores />
            <Rh />
            <AvatarMenu />
            <hr ></hr>

          </Row>
          <div className='buttonLinks'>
            <Button> Links</Button>
          </div>
          <div className='buttonsMenu'>
            {menuItems.map((item, index) => (
              <MenuItemWithTooltip
                key={index}
                link={item.link}
                image={item.image}
                tooltipText={item.tooltipText}
              />
            ))}
          </div>
        </Container >


      </>
    )
  }

}

export default Menu

