import React from 'react'
import { DropdownButton, Dropdown, Col } from 'react-bootstrap'

export default function comercial() {
    return (
        <Col>
            <DropdownButton className='custom-dropdown'
                key='down'
                id={`dropdown-button-drop-down`}
                drop='down'
                variant="secondary"
                title='Comercial'
            >
                <DropdownButton className='custom-dropdown'
                key='end'
                id={`dropdown-button-drop-end`}
                drop='end'
                variant="secondary"
                title='Site'
            >  
                <Dropdown.Item href="/comercial/lead/semviabilidade/">LEAD Inviabilizadas</Dropdown.Item>
                <Dropdown.Item href="/comercial/parceiro/">Parceiro</Dropdown.Item>
                <Dropdown.Item href="/comercial/indiqueumamigo/">Indique Um Amigo</Dropdown.Item>
                </DropdownButton>
            <DropdownButton className='custom-dropdown'
                key='end'
                id={`dropdown-button-drop-end`}
                drop='end'
                variant="secondary"
                title='Vemdas Externas'
            >  
                <Dropdown.Item href="/comercial/lead/semviabilidade/">LEAD Inviabilizadas</Dropdown.Item>
                <Dropdown.Item href="/comercial/parceiro/">Parceiro</Dropdown.Item>
                <Dropdown.Item href="/comercial/indiqueumamigo/">Indique Um Amigo</Dropdown.Item>
                </DropdownButton>
            
            </DropdownButton>
        </Col>
    )
}
