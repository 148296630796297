import React, { Component } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip, Legend } from 'recharts'
import axios from 'axios';
import './BotDepartamentos.css'

export class Chats extends Component {
  constructor() {
    super();
    this.state = {
      dataIni: '',
      dataEnd: '',
      data: [],
      departamentos: "",
      chartDados: false
    };
  };

  componentDidMount() {
    console.log(this.props.urlApi)
  }
  async geradorRelatorioChat() {
    var chartDados = true
    var urlAPI = localStorage.getItem('urlAPI')
    var dataIni = this.state.dataIni
    var dataEnd = this.state.dataEnd
    var departamentos = this.state.departamentos
    var dadosChat = []
    if (dataIni >= dataEnd) {
      console.log('A data inicial deve ser menor que a data final')
    } else {
      console.log(this.state.dataIni, this.state.dataEnd)
      var postData = {
        dataIni,
        dataEnd,
        departamentos
      };

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Token ' + localStorage.getItem('token')
        },
        credentials: "include",
      }
      await axios.post(urlAPI + 'consulta/bot/departamentos/', postData, axiosConfig)
        .then(dados => {
          var chat = dados.data
          this.setState({
            data: chat,
            chartDados,
          })



        })
      // this.setState({data:dadosChat})

    }

  }

  render() {
    return (
      <div className="container">
        <h1>Chats</h1>
        <Form >
          <Form.Label>Departamento</Form.Label>
          <Form.Select aria-label="Departamento" onChange={(e) => this.setState({ departamentos: e.target.value })}>
            <option hidden>--Departamento--</option>
            <option value="Comercial">Comercial</option>
            <option value="Suporte Técnico">Suporte Técnico</option>
            <option value="Cancelamento">Cancelamento</option>
            <option value="Financeiro">Financeiro</option>
            <option value="Pós-Venda">Pós-Venda</option>
          </Form.Select>
          <Row>
            <Col>
              <Form.Label>Data Inicio</Form.Label>
              <Form.Control type="date" placeholder="Inicio" onChange={(e) => this.setState({ dataIni: e.target.value })} />
            </Col>
            <Col>
              <Form.Label>Data Fim</Form.Label>
              <Form.Control type="date" placeholder="Fim" onChange={(e) => this.setState({ dataEnd: e.target.value })} />
            </Col>
          </Row>
          <Button onClick={() => this.geradorRelatorioChat()} >Gerar</Button>
        </Form>
        {this.state.chartDados ? 
        <div className='chartDados'>
          < BarChart width={930} height={500} data={this.state.data} >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="DepartamentoData" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Departamentos" fill="#8884d8" label="Financeiro" />
          </BarChart >
        </div > : <div></div>}

      </div>
    )
  }
}

export default Chats